:root {
  --font-1: "TT-Backward-Sans-Bold";
  --font-2: "Avenir-Medium";
  --font-3: "Selfie-Printed";
  --font-4: "RoughCut-Regular";
  --font-5: "Pitch-Regular";
}

@font-face {
  font-family: TT-Backward-Sans-Bold;
  src: url("https://assets.lunchbox.io/greydog/web/fonts/TT-Backward-Sans-Bold.otf")
    format("opentype");
}

@font-face {
  font-family: Avenir-Medium;
  src: url("https://assets.lunchbox.io/greydog/web/fonts/Avenir-Medium.otf")
    format("opentype");
}

@font-face {
  font-family: Selfie-Printed;
  src: url("https://assets.lunchbox.io/greydog/web/fonts/Selfie-Printed.otf")
    format("opentype");
}

@font-face {
  font-family: RoughCut-Regular;
  src: url("https://assets.lunchbox.io/greydog/web/fonts/RoughCut-Regular.otf")
    format("opentype");
}

@font-face {
  font-family: Pitch-Regular;
  src: url("https://assets.lunchbox.io/greydog/web/fonts/Pitch-Regular.ttf")
    format("truetype");
}
