:root {
  --focus-text-color: #506568;
  --focus-ring-color: #d1dfdd;
}.base {
  
  
  background-color: #FFFFFF;
}
.alternateGray {
  
  
  background-color: #F5F5F5;
}
.accentDark {
  
  
  background-color: #506568;
}
.accentDarkRounded {
  
  
  border-radius: 5px;
  background-color: #506568;
}
.accentLightBordered {
  &:active {
    border-color: #FFFFFF;
  }
  
  border-width: 2px;
  border-style: solid;
  border-color: #FFFFFF;
  background-color: #d1dfdd;
}
.alternateGrayRounded {
  
  
  border-radius: 3px;
  background-color: #F5F5F5;
}
.alternateGrayBordered {
  &:active {
    border-color: #F5F5F5;
  }
  
  border-width: 2px;
  border-style: solid;
  border-color: #F5F5F5;
  background-color: #F5F5F5;
}
.alternateGrayCircle {
  
  
  border-radius: 100px;
  background-color: #F5F5F5;
}
.alternateGrayAccentDarkCircle {
  &:active {
    border-color: #506568;
  }
  
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  border-color: #506568;
  background-color: #F5F5F5;
}
.accentLight {
  
  
  background-color: #d1dfdd;
}
.baseRounded {
  
  
  border-radius: 10px;
  background-color: #FFFFFF;
}
.accentDarkBordered {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #506568;
  background-color: #FFFFFF;
}
.baseBordered {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #2D2D2D;
  background-color: #FFFFFF;
}
.baseGrayBordered {
  
  
  border-width: 1px;
  border-style: solid;
  border-color: #989898;
  background-color: #FFFFFF;
}
.baseBackgroundGray {
  
  
  background-color: #F7F7F7;
}
.accentLightLightBordered {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #d1dfdd;
  background-color: #d1dfdd;
}
.baseBorderedRounded {
  
  
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #2D2D2D;
  background-color: #FFFFFF;
}
.accentLightRounded {
  
  
  border-radius: 5px;
  background-color: #d1dfdd;
}
.basePinkCircle {
  
  
  border-radius: 100px;
  background-color: #f49667;
}
.basePinkRounded {
  
  
  border-radius: 3px;
  background-color: #f49667;
}
.basePinkBordered {
  
  
  border-width: 3px;
  border-style: solid;
  border-color: #f49667;
  background-color: #FFFFFF;
}
.baseAccentBordered {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #d1dfdd;
  background-color: #FFFFFF;
}
.basePinkBorderedRound {
  
  
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #f49667;
}
.baseAccentLightBorderedRound {
  
  
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  border-color: #d1dfdd;
  background-color: #FFFFFF;
}
.baseAccentLightVeryHeavyBordered {
  
  
  border-width: 5px;
  border-style: solid;
  border-color: #d1dfdd;
  background-color: #FFFFFF;
}
.baseAccentLightHeavyBordered {
  
  
  border-width: 3px;
  border-style: solid;
  border-color: #d1dfdd;
  background-color: #FFFFFF;
}
.baseCircle {
  
  
  border-radius: 100px;
  background-color: #FFFFFF;
}
.baseBorderedCircle {
  
  
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  border-color: #006269;
  background-color: #FFFFFF;
}
.baseAccentLightCircle {
  
  
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  border-color: #d1dfdd;
  background-color: #FFFFFF;
}
.accentLightCircle {
  
  
  border-radius: 100px;
  background-color: #d1dfdd;
}.title1 {font-family: "Selfie-Printed";
  font-size: 2.5rem;
  font-weight: 400;}
.title2 {font-family: "TT-Backward-Sans-Bold";
  font-size: 1.25rem;
  font-weight: 400;}
.title3 {font-family: "TT-Backward-Sans-Bold";
  font-size: 1rem;
  font-weight: 400;}
.title4 {font-family: "TT-Backward-Sans-Bold";
  font-size: 1.875rem;
  font-weight: 400;}
.title5 {font-family: "Selfie-Printed";
  font-size: 2.25rem;
  font-weight: 400;}
.title6 {font-family: "TT-Backward-Sans-Bold";
  font-size: 1.625rem;
  font-weight: 400;}
.title7 {font-family: "RoughCut-Regular";
  font-size: 1.5rem;
  font-weight: 400;}
.subtitle1 {font-family: "Avenir-Medium";
  font-size: 1rem;
  font-weight: 400;}
.subtitle2 {font-family: "TT-Backward-Sans-Bold";
  font-size: 0.8125rem;
  font-weight: 400;}
.subtitle3 {font-family: "TT-Backward-Sans-Bold";
  font-size: 1rem;
  font-weight: 400;}
.body1 {font-family: "Avenir-Medium";
  font-size: 0.875rem;
  font-weight: 400;}
.body2 {font-family: "TT-Backward-Sans-Bold";
  font-size: 1.125rem;
  font-weight: 400;}
.body3 {font-family: "Avenir-Medium";
  font-size: 1rem;
  font-weight: 400;}
.body4 {font-family: "Avenir-Medium";
  font-size: 1.25rem;
  font-weight: 400;}
.body5 {font-family: "Selfie-Printed";
  font-size: 1.625rem;
  font-weight: 400;}
.body6 {font-family: "Pitch-Regular";
  font-size: 0.875rem;
  font-weight: 400;}.title1_black {@extend .title1;  color: #006269;
  text-transform: initial;}
.title2_black {@extend .title2;  color: #006269;
  text-transform: uppercase;}
.title2_basePink {@extend .title2;  color: #f49667;
  text-transform: uppercase;}
.title2_baseWhite {@extend .title2;  color: #FFFFFF;
  text-transform: uppercase;}
.title2_baseGray {@extend .title2;  color: #989898;
  text-transform: uppercase;}
.title2_baseBlack {@extend .title2;  color: #2D2D2D;
  text-transform: uppercase;}
.title2_accentLight {@extend .title2;  color: #d1dfdd;
  text-transform: uppercase;}
.title2_accentDark {@extend .title2;  color: #506568;
  text-transform: uppercase;}
.title3_black {@extend .title3;  color: #006269;
  text-transform: uppercase;}
.title3_baseWhite {@extend .title3;  color: #FFFFFF;
  text-transform: uppercase;}
.title3_baseBlack {@extend .title3;  color: #2D2D2D;
  text-transform: uppercase;}
.title3_accentDark {@extend .title3;  color: #506568;
  text-transform: uppercase;}
.title4_accentLight {@extend .title4;  color: #d1dfdd;
  text-transform: uppercase;}
.title4_basePink {@extend .title4;  color: #f49667;
  text-transform: uppercase;}
.title4_black {@extend .title4;  color: #006269;
  text-transform: uppercase;}
.title4_baseBlack {@extend .title4;  color: #2D2D2D;
  text-transform: uppercase;}
.title4_accentDark {@extend .title4;  color: #506568;
  text-transform: uppercase;}
.title5_accentDark {@extend .title5;  color: #506568;
  text-transform: initial;}
.title6_accentDark {@extend .title6;  color: #506568;
  text-transform: initial;}
.title6_accentDark_cap {@extend .title6;  color: #506568;
  text-transform: uppercase;}
.title7_accentDark {@extend .title7;  color: #506568;
  text-transform: initial;}
.subtitle1_black {@extend .subtitle1;  color: #006269;
  text-transform: initial;}
.subtitle1_baseBlack {@extend .subtitle1;  color: #2D2D2D;
  text-transform: initial;}
.subtitle1_accentDark {@extend .subtitle1;  color: #506568;
  text-transform: initial;}
.subtitle1_baseWhite {@extend .subtitle1;  color: #FFFFFF;
  text-transform: initial;}
.subtitle2_baseWhite {@extend .subtitle2;  color: #FFFFFF;
  text-transform: initial;}
.subtitle2_accentDark {@extend .subtitle2;  color: #506568;
  text-transform: initial;}
.subtitle3_black {@extend .subtitle3;  color: #006269;
  text-transform: initial;}
.subtitle3_baseBlack {@extend .subtitle3;  color: #2D2D2D;
  text-transform: initial;}
.subtitle3_basePink {@extend .subtitle3;  color: #f49667;
  text-transform: initial;}
.subtitle3_accentDark {@extend .subtitle3;  color: #506568;
  text-transform: initial;}
.body1_black {@extend .body1;  color: #006269;
  text-transform: initial;}
.body1_basePink {@extend .body1;  color: #f49667;
  text-transform: initial;}
.body1_baseWarning {@extend .body1;  color: #FF2A00;
  text-transform: initial;}
.body1_accentDark {@extend .body1;  color: #506568;
  text-transform: initial;}
.body1_baseGray {@extend .body1;  color: #989898;
  text-transform: initial;}
.body1_baseWhite {@extend .body1;  color: #FFFFFF;
  text-transform: initial;}
.body1_baseBlack {@extend .body1;  color: #2D2D2D;
  text-transform: initial;}
.body1_baseLink {@extend .body1;  color: #1173FF;
  text-transform: initial;}
.body1_accentLight {@extend .body1;  color: #d1dfdd;
  text-transform: initial;}
.body1_baseWhite_cap {@extend .body1;  color: #FFFFFF;
  text-transform: uppercase;}
.body2_black {@extend .body2;  color: #006269;
  text-transform: initial;}
.body2_accentDark {@extend .body2;  color: #506568;
  text-transform: initial;}
.body2_baseWhite {@extend .body2;  color: #FFFFFF;
  text-transform: initial;}
.body2_baseGray {@extend .body2;  color: #989898;
  text-transform: initial;}
.body2_baseWarning {@extend .body2;  color: #FF2A00;
  text-transform: initial;}
.body3_accentDark {@extend .body3;  color: #506568;
  text-transform: initial;}
.body3_baseBlack {@extend .body3;  color: #2D2D2D;
  text-transform: initial;}
.body4_basePink {@extend .body4;  color: #f49667;
  text-transform: initial;}
.body4_accentDark {@extend .body4;  color: #506568;
  text-transform: initial;}
.body4_baseBlack {@extend .body4;  color: #2D2D2D;
  text-transform: initial;}
.body5_accentDark {@extend .body5;  color: #506568;
  text-transform: initial;}
.body6_accentDark {@extend .body6;  color: #506568;
  text-transform: initial;}.buttonPrimaryStandard {
  &:active {
    @extend .title2_accentLight;
background-color: #FFFFFF;
  }
  
  @extend .title2_baseWhite;
@extend .accentLightRounded;
}
.buttonPrimaryAccentDarkStandard {
  &:active {
    @extend .title2_accentLight;
  }
  
  @extend .title2_baseWhite;
@extend .accentDarkRounded;
}
.buttonPrimaryAccentDarkStandardRounded {
  &:active {
    @extend .title3_baseWhite;
  }
  
  @extend .title3_baseWhite;
@extend .accentDarkRounded;
}
.buttonPrimaryStandardBordered {
  &:active {
    @extend .title2_accentLight;
background-color: #FFFFFF;
  }
  
  @extend .title2_baseWhite;
@extend .accentLightLightBordered;
}
.buttonPrimaryBasePinkBordered {
  &:active {
    @extend .title2_accentLight;
background-color: #FFFFFF;
  }
  
  @extend .title2_baseWhite;
@extend .basePinkRounded;
}
.buttonPrimaryClear {
  &:active {
    @extend .title2_baseWhite;
background-color: #d1dfdd;
  }
  
  @extend .title2_black;
@extend .base;
}
.buttonPrimaryAccentLightBordered {
  &:active {
    @extend .title2_baseWhite;
background-color: #d1dfdd;
  }
  
  @extend .title2_black;
@extend .baseAccentBordered;
}
.buttonNavStandard {
  &:active {
    @extend .title2_accentDark;
  }
  
  @extend .title2_accentLight;
@extend .alternateGray;
}
.buttonNavStandardBaseWhite {
  &:active {
    @extend .title2_accentDark;
  }
  
  @extend .title2_baseGray;
@extend .base;
}
.buttonFooterStandard {
  &:active {
    @extend .title2_baseWhite;
  }
  
  @extend .title2_baseWhite;
@extend .accentDark;
}
.buttonSecondary {
  
  
  @extend .body2_black;
}
.buttonSecondaryBordered {
  &:active {
    @extend .body2_baseWhite;
background-color: #2D2D2D;
  }
  
  @extend .body2_black;
@extend .baseBorderedRounded;
}
.buttonSecondaryAccentDark {
  
  
  @extend .subtitle3_accentDark;
}
.buttonSecondaryBasePink {
  
  
  @extend .subtitle3_basePink;
}
.buttonTertiaryBasePink {
  
  
  @extend .body1_basePink;
}
.buttonTertiaryBaseGray {
  
  
  @extend .body1_baseGray;
}
.buttonTertiaryAccentDark {
  
  
  @extend .body1_accentDark;
}
.buttonTertiaryAccentLightBordered {
  &:active {
    @extend .body1_baseWhite;
background-color: #d1dfdd;
  }
  
  @extend .body1_accentLight;
@extend .baseAccentLightCircle;
}
.buttonTertiaryLink {
  
  
  @extend .body1_baseLink;
}
.buttonTertiaryCircle {
  &:active {
    @extend .body1_baseWhite;
background-color: #d1dfdd;
  }
  
  @extend .body1_accentDark;
@extend .baseCircle;
}
.buttonTertiaryCircleBasePink {
  &:active {
    @extend .subtitle2_baseWhite;
  }
  
  @extend .subtitle2_baseWhite;
@extend .basePinkCircle;
}
.buttonTertiaryCircleAccentLight {
  &:active {
    @extend .body2_accentDark;
  }
  
  @extend .body2_accentDark;
@extend .accentLightCircle;
}
.buttonOptionRadio {
  &:active {
    @extend .body1_accentDark;
background-color: #F5F5F5;
  }
  
  @extend .body1_accentDark;
background-color: #F7F7F7;
@extend .alternateGrayAccentDarkCircle;
}
.buttonOptionRadioPink {
  &:active {
    @extend .body1_baseWhite;
background-color: #f49667;
  }
  
  @extend .body1_baseWhite;
@extend .basePinkCircle;
}
.buttonTertiaryRadio {
  &:active {
    @extend .body1_baseWhite;
background-color: #f49667;
  }
  
  @extend .body1_baseBlack;
@extend .basePinkBorderedRound;
}.primary {
@extend .body1_accentDark;
 &::placeholder {
    @extend .body1_accentDark;
  }
}