$locationCard-shadow: false;
$accentLight: #d1e0dd;
$basePink: #f49667;

%location {
  background-color: white;
  .location {
    &-container {
      &--header {
        box-shadow: none;
        & .body {
          overflow: hidden;
        }
        & .tabs {
          &-container {
            padding: 1rem 1.5rem 0;
          }
        }
      }
    }
  }
}

%card {
  .card {
    &-body {
      span:first-child {
        span {
          display: none !important;
        }
      }
    }
  }
}

%itemCard {
  position: relative;
  .item-card {
    &-title {
      min-height: 1.5rem;
    }
    &-description {
      min-height: 3rem;
      &-desc {
        max-height: 33px;
      }
      &-detail {
        padding-top: 0px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    &-info {
      &-image-container {
        &-image {
          border-radius: 5px;
        }
      }
      &-add-button {
        transform: translate(-50%, -100%);
        padding: 5px;
        width: 50%;
      }
    }
  }
}

%itemDetails {
  .wrapper {
    align-self: flex-end !important;
  }
  .itemDetails {
    &-footer {
      button {
        background-color: white !important;
        span {
          color: #4f6467 !important;
        }
      }
      > div {
        flex-direction: column;
        > div {
          width: 100%;
        }
      }
    }
  }
}

%quantitySelector {
  padding-bottom: 0 !important;
}

%packingItem {
  .packingItem {
    &-price {
      & > div {
        padding-top: 0px;
      }
      & > div::before {
        content: " " !important;
      }
      & > div > div {
        margin-right: 0px !important;
      }
    }
  }
}

%group {
  .group-info {
    margin-bottom: 0px;
    &-container {
      border-bottom: 2px solid $accentLight;
    }
  }
}

%option {
  margin-bottom: -20px !important;
}

%itemDetails-tabs {
  .nav-item[data-active="1"] {
    border-bottom: 2px solid #4f6467;
  }
}

%locationCard {
  min-height: 200px;

  .address {
    line-height: 1.5;
  }

  @media screen and (max-width: 48em) {
    .locationCard-name {
      font-size: 1.5rem;
    }
  }

  .order-button {
    margin-top: 1rem;
  }
}

%cartItem {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  .cartItem {
    &-actions {
      .cart-btn {
        border: none;
        &:last-child {
          span {
            color: $basePink;
          }
        }
      }
    }
  }
}

%confirm {
  button {
    padding-top: 12px;
  }
}

%giftCard {
  .giftCard {
    &--logo {
      height: 7.8125rem;
    }
    &-left {
      &--value {
        transform: translateY(-175%);
        margin: 0rem;
      }
    }
  }
}

%option {
  margin-bottom: 2rem !important;
}
